/*
 * Root font size
 */
html {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  line-height: 1.6em;
}

/*
 * Fill the window
 */
html,
body,
#rbc {
  width: 100%;
  height: 100%;
}

body {
  color: #faf4f5;
  background-color: white;
}

* {
  box-sizing: border-box;
}
